import Divider from '@/newComponents/Divider/Divider';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import IconWithLabel from '@/newComponents/IconWithLabel/IconWithLabel';
import Badge from '@/newComponents/Badge/Badge';
import Card from '@/newComponents/Card/Card';
import Button from '@/newComponents/Button/Button';
import commonStyles from '@/global/style/common.module.css';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { ApiResponseError, Brand, CmsSettings, MetaData, Offer } from '@/types';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { gtmSelectItemListItem, gtmSelectPromotion, gtmViewPromotion } from '@/global/gtm';
import { generateGtmLineItemFromRaw } from '@/global/gtm/helpers';
import { useAppContext } from '@/global/hooks/useAppContext';
import useMatchMedia from '@/global/hooks/useMatchMedia';
import useToastNotice from '@/global/hooks/useToastNotice';
import useTracking from '@/global/hooks/useTracking';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import { breakpoints } from '@/global/style/breakpoint';
import { Color, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { addToCart, getAddToCartError } from '@/global/utils/AddToCart';
import {
  checkHasUnlimitedData,
  gbToMusicTime,
  gbToVideoTime,
  getAmountGbNumber,
  surfDataPretty,
} from '@/global/utils/Calculate';
import { getCmsTextWithValues } from '@/global/utils/Cms';
import { formatDisplayPrice } from '@/global/utils/Format';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import OffersJson from '@/pages/api/cms/offers.json';
import { OfferCardUnlimitedData } from '@/newComponents/OfferCard/OfferCardUnlimitedData';
import OfferCardBottomDetails from './OfferCardBottomDetails/OfferCardBottomDetails';
import OfferCardSticky from './OfferCardSticky/OfferCardSticky';
import styles from './OfferCard.module.css';

type OfferCardProps = {
  data?: Offer;
  highlight: boolean;
  type: string;
  selectedFilter: string;
  productsData: MetaData['products'];
  itemListName: string;
  offerCallout?: string;
  renderDetails?: boolean;
  selected?: boolean;
  mobileBrowserClickHandler: (ratorPackageId: string) => void;
  setCardContentHeight?: (height: number) => void;
  regularSurfInfoExists: boolean;
  heighestCardContent?: number;
  userId: string | null;
};

const OfferCard = (props: OfferCardProps) => {
  const {
    data,
    highlight,
    type,
    selectedFilter,
    productsData,
    itemListName,
    offerCallout,
    renderDetails,
    setCardContentHeight,
    heighestCardContent,
    regularSurfInfoExists,
    selected,
    mobileBrowserClickHandler,
    userId,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const offerCardButtonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);
  const [showStickyOffer, setShowStickyOffer] = useState(false);
  const isDesktop = useMatchMedia(`(min-width:calc(${breakpoints.sm}))`);
  const [isLoading, setIsLoading] = useState(false);
  const [hasTrackedPromotionViewed, setHasTrackedPromotionViewed] = useState(false);
  const { account } = useAppContext();
  const { type: userBrand } = account || {};

  useEffect(() => {
    if (ref.current) setCardContentHeight?.(ref.current.clientHeight);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current?.clientHeight]);

  const { offers } = OffersJson;
  const { settings } = useCmsContext<{ settings: CmsSettings }>();
  const { errorMessages } = settings;
  const productData = Object.values(productsData || {})[0]?.find((product) => product.id === data?.ratorPackageId);
  const amountGB = getAmountGbNumber(productData?.dataAmount ?? 0);
  const dataEuAmount = getAmountGbNumber(productData?.dataEuAmount ?? 0);
  const hasUnlimitedData = checkHasUnlimitedData(productData?.dataAmount);
  const isB2b = productData?.brand === Brand.B2B;
  const isPrepaid = productData?.ratePlan === 'NonContract';

  const toast = useToastNotice();
  const { utmTracking, salesTracking } = useTracking();

  // Sticky offer on mobile when scrolling past the offer button
  useEffect(() => {
    const checkScroll = () => {
      if (!offerCardButtonRef.current || isDesktop || !selected) {
        setShowStickyOffer(false);
        return;
      }

      const buttonRect = offerCardButtonRef.current.getBoundingClientRect();

      if (buttonRect.bottom < 0) {
        setShowStickyOffer(true);
      } else {
        setShowStickyOffer(false);
      }
    };

    checkScroll();

    window.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, [isDesktop, selected]);

  useEffect(() => {
    if (showStickyOffer && data && productData && !hasTrackedPromotionViewed) {
      const gtmLineItem = generateGtmLineItemFromRaw(data, productData, itemListName);
      gtmViewPromotion('StickyOfferCard', itemListName, gtmLineItem);
      setHasTrackedPromotionViewed(true);
    }
  }, [data, hasTrackedPromotionViewed, itemListName, productData, showStickyOffer]);

  const handleCardClick = (e: SyntheticEvent) => {
    // When clicking a link we don't want to prevent the default behavior i.e not opening the link
    const clickedLink = (e.target as HTMLElement).tagName === 'A';
    if (clickedLink) return null;

    if (!isDesktop && !selected) {
      if (data) {
        gtmSelectItemListItem(generateGtmLineItemFromRaw(data, productData, itemListName));
      }
      return mobileBrowserClickHandler(data?.ratorPackageId ?? '');
    }
    e.preventDefault();
    return null;
  };

  const addProductToCart = async (promotionClick = false) => {
    if (!productData || !data) return null;
    if (!isDesktop && !selected) {
      return mobileBrowserClickHandler(data?.ratorPackageId ?? '');
    }

    const gtmLineItem = generateGtmLineItemFromRaw(data, productData, itemListName);
    if (!promotionClick) {
      gtmSelectItemListItem(gtmLineItem);
    } else {
      gtmSelectPromotion('StickyOfferCard', itemListName, gtmLineItem);
    }

    try {
      setIsLoading(true);

      await addToCart({
        brand: productData.brand,
        offer: data,
        productData,
        userId,
        utmTracking,
        salesTracking,
        promotionName: promotionClick ? itemListName : undefined,
      });

      let checkoutUrl = '/checkout';

      if (productData?.brand === Brand.B2B) {
        checkoutUrl = `/foretag${checkoutUrl}`;
      }

      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Failed to add product to cart', error); // eslint-disable-line no-console
      toast({
        dataTestId: 'add-to-cart-error-toast',
        preset: NoticeFieldPreset.Error,
        ...getAddToCartError(error as ApiResponseError, errorMessages, userBrand),
      });
      setIsLoading(false);
    }
  };

  const offerPrice = formatDisplayPrice(data?.offerPrice, isB2b);
  const regularPrice = formatDisplayPrice(productData?.price?.amount, isB2b, productData?.price?.amountExclVat);
  const priceText = isPrepaid ? offers.offerCards.prepaidPrice : offers.offerCards.price;
  const formattedPriceText = getCmsTextWithValues(priceText, {
    offerPrice: data?.offerPrice ? offerPrice : regularPrice,
  });

  const getPrepaidInfo = () => {
    if (!productData?.price?.amount || !offerPrice) return null;

    return (
      <Typography
        data-testid="offer-card-prepaid-original-price"
        align="center"
        type={TypographyType.Detail}
        text={getCmsTextWithValues(offers.offerCards.prePaidFineprintLine2, {
          originalPrice: regularPrice,
        })}
      />
    );
  };

  const getContractInfo = (price: string, offerDuration: string | undefined) => {
    if (!price) return null;
    const contentString = [
      getCmsTextWithValues(offers.offerCards.offerDuration, {
        offerDuration: offerDuration ?? '',
      }),
      offers.offerCards.thereafter,
    ].join(' ');
    const priceString = getCmsTextWithValues(offers.offerCards.price, {
      offerPrice: regularPrice,
    });

    return (
      <div data-testid="offer-card-contract-info">
        <Typography
          elementAs="div"
          className={commonStyles.inline}
          type={TypographyType.Detail}
          text={<HtmlFromCMS richText html={contentString} className={commonStyles.inline} />}
        />
        <Typography
          elementAs="span"
          className={commonStyles.inline}
          text={` ${priceString}`}
          fontWeight={FontWeight.Medium}
        />
      </div>
    );
  };

  if (!data || selectedFilter !== type) return null;

  const { regularSurfAmount, welcomeData, extraUsp, offerDuration, previousCampaignPrice } = data;

  const offerCardMainContentInlineStyle = {
    '--offercard-highest-card-content-height': `${heighestCardContent}px`,
  } as React.CSSProperties;

  return (
    <>
      <div
        className={classNames(styles.offerCardContainer, {
          [styles.adjustForBadge]: selected && Boolean(offerCallout),
        })}
      >
        {offerCallout && (isDesktop || selected) && (
          <Badge
            className={styles.offerBadge}
            text={offerCallout}
            data-testid="offer-card-callout-badge"
            colorVariant={Color.Lemon3}
          />
        )}

        <Card
          className={classNames(styles.offerCard, {
            [styles.selected]: Boolean(selected),
            [styles.highlighted]: highlight,
          })}
          data-testid="offer-card"
          onClick={handleCardClick}
          topPadding={false}
          bottomPadding={false}
        >
          <div className={styles.offerCardMainContentWrapper} ref={ref} style={offerCardMainContentInlineStyle}>
            <div
              className={classNames(styles.priceDataWrapper, {
                [styles.priceDataWrapperSelected]: selected,
                [styles.adjustForRegularSurf]: regularSurfInfoExists,
              })}
            >
              {hasUnlimitedData ? (
                <OfferCardUnlimitedData offersLabels={offers} />
              ) : (
                <Typography
                  data-testid="offer-card-data-amount"
                  type={TypographyType.ExpressiveL}
                  text={surfDataPretty(productData?.dataAmount ?? 0)}
                  align="center"
                />
              )}

              {(isDesktop || selected) && regularSurfAmount && (
                <Typography
                  text={`${regularSurfAmount} GB`}
                  type={TypographyType.ExpressiveL}
                  fontWeight={FontWeight.Regular}
                  strikethrough
                />
              )}

              <Typography
                data-testid="offer-card-monthly-price"
                type={TypographyType.ExpressiveL}
                text={formattedPriceText}
                color={Color.Hallon1}
                align="center"
              />
            </div>
            <div
              className={classNames(styles.headerExtraInfoWrapper, {
                [styles.headerExtraInfoWrapperHidden]: !selected,
              })}
            >
              {isPrepaid ? getPrepaidInfo() : getContractInfo(offerPrice, offerDuration)}
              {previousCampaignPrice.length > 0 && (
                <Typography
                  data-testid="offer-card-previous-campaign-price"
                  align="center"
                  type={TypographyType.Detail}
                  text={<HtmlFromCMS html={getCmsTextWithValues(previousCampaignPrice)} />}
                />
              )}
            </div>

            {offerCallout && !selected && (
              <Badge
                className={styles.offerAppUnselectedBadge}
                text={offerCallout}
                data-testid="offer-card-callout-badge"
                colorVariant={Color.Lemon3}
              />
            )}
            <div className={classNames(styles.buttonUspWrapper, { [styles.buttonUspWrapperHidden]: !selected })}>
              <Divider color={Color.Gray2} marginY={spacing.x2} />
              {isPrepaid && (
                <Typography
                  data-testid="offer-card-prepaid-duration"
                  align="center"
                  type={TypographyType.Detail}
                  text={offers.offerCards.prepaidDuration}
                />
              )}
              <Divider />

              {welcomeData && (
                <IconWithLabel
                  className={styles.offerUspIconContainer}
                  label={
                    <Typography
                      data-testid="offer-card-welcome-data"
                      type={TypographyType.Detail}
                      text={`${welcomeData} GB extra surfpott`}
                    />
                  }
                  icon={<SvgIcon icon="gift" size="small" />}
                />
              )}
              {extraUsp && (
                <IconWithLabel
                  className={styles.offerUspIconContainer}
                  label={<Typography data-testid="offer-card-extra-usp" type={TypographyType.Detail} text={extraUsp} />}
                  icon={<SvgIcon icon="thumbs-up" size="small" />}
                />
              )}
              {renderDetails && dataEuAmount > 0 && (
                <IconWithLabel
                  className={styles.offerUspIconContainer}
                  label={
                    <Typography
                      data-testid="offer-card-data-eu-amount"
                      type={TypographyType.Detail}
                      text={
                        <HtmlFromCMS
                          html={getCmsTextWithValues(offers.offerCards.dataEuAmountText, {
                            dataEuAmount: dataEuAmount.toString(),
                          })}
                        />
                      }
                    />
                  }
                  icon={<SvgIcon icon="aeroplane" size="small" />}
                />
              )}
              <Button
                className={styles.offerCardButton}
                data-testid="offercard-button"
                centered
                label={offers.offerCards.buttonText}
                onClick={() => addProductToCart()}
                isLoading={isLoading}
                ref={offerCardButtonRef}
              />
            </div>
          </div>

          {renderDetails && (isDesktop || selected) && (
            <OfferCardBottomDetails
              header={offers.offerCards.bodyTextBlack}
              hasUnlimitedData={hasUnlimitedData}
              body={
                <HtmlFromCMS
                  html={getCmsTextWithValues(offers.offerCards.bodyTextRegular, {
                    dataAmount: surfDataPretty(productData?.dataAmount ?? 0),
                  })}
                />
              }
              listItems={[
                {
                  label: getCmsTextWithValues(offers.offerCards.mediaInfoTextMusic, {
                    gbToMusicTime: gbToMusicTime(amountGB),
                  }),
                  icon: 'music',
                },
                {
                  label: getCmsTextWithValues(offers.offerCards.mediaInfoTextVideo, {
                    gbToVideoTime: gbToVideoTime(amountGB),
                  }),
                  icon: 'video',
                },
              ]}
            />
          )}
        </Card>
      </div>

      {showStickyOffer && (
        <OfferCardSticky
          productData={productData}
          addProductToCart={() => addProductToCart(true)}
          isLoading={isLoading}
          offersLabels={offers}
          regularSurfAmount={regularSurfAmount}
          formattedPriceText={formattedPriceText}
          offerDuration={offerDuration}
          regularPrice={regularPrice}
        />
      )}
    </>
  );
};

export default OfferCard;
