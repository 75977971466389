import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { Color } from '@/global/style/variables';
import OffersJson from '@/pages/api/cms/offers.json';
import styles from './OfferCardUnlimitedData.module.css';

export const OfferCardUnlimitedData = ({ offersLabels }: { offersLabels: (typeof OffersJson)['offers'] }) => {
  return (
    <div className={styles.wrapper}>
      <SvgIcon icon="unlimited" color={Color.Hallon1} size="small" />
      <Typography type={TypographyType.ExpressiveL} align="center">
        {offersLabels.offerCards.unlimited}
      </Typography>
    </div>
  );
};
