export const ApiUrl = {
  baseApi: process.env.BASE_API_URL,
  baseUrl: process.env.BASE_URL,
  cmsAssetsUrl: process.env.CMS_RETURN_URL,
  webClientApi: `${process.env.BASE_URL}/api`,
  webClientInternalApi: `${process.env.BASE_URL_INTERNAL}/api`,
  accountApi: `${process.env.ACCOUNT_API}/api/v1`,
  authApi: `${process.env.AUTH_API}/api/v1`,
  cartApi: `${process.env.CART_API}/api/v1`,
  cmsApi: `${process.env.CMS_API}/api/v1`,
  orderApi: `${process.env.ORDER_API}/api/v1`,
  paymentApi: `${process.env.PAYMENT_API}/api/v1`,
  productApi: `${process.env.PRODUCT_API}/api/v1`,
  // proxy are endspoints that targets the Api-services directly but proxied via next.config.js. This to avoid cors on localhost
  proxy: {
    orderApi: '/order/api/v1',
  },
};

export enum CmsPageType {
  ArticlePage = 'ArticlePage',
  ErrorPage = 'ErrorPage',
  GenericBlockPage = 'GenericBlockPage',
  HardwareListingPage = 'HardwareListingPage',
  HardwareProduct = 'HardwareProduct',
  HardwareProductPage = 'HardwareProductPage',
  LandingPage = 'LandingPage',
  SavedeskPage = 'SavedeskPage',
  StartPage = 'StartPage',
}

export enum Layout {
  LoginLayout = 'LoginLayout',
  MyPagesLayout = 'MyPagesLayout',
  SiteLayout = 'SiteLayout',
}

export const SelectedAccountIdCookie = 'HALLON_SELECTED_ACCOUNT';
export const StoredB2CCartIdCookie = 'HALLON_STORED_B2C_CART_ID';
export const StoredB2BCartIdCookie = 'HALLON_STORED_B2B_CART_ID';
export const IsAppCookie = 'isApp';
export const HallonHSubId = 'hallon_hsubid';
export const HallonIdentificationReference = 'hallon_identification_reference';
export const GclidSessionStorageKey = 'gclid';
export const CmsPageTrackingSessionStorageKey = 'cmsPageTracking';

export enum LoginView {
  B2C_BANKID = 'B2C_BANKID',
  B2C_EMAIL = 'B2C_EMAIL',
  B2B_EMAIL = 'B2B_EMAIL',
}

export enum PostMessageType {
  HALLON_AUTHENTICATED_USER = 'HALLON_AUTHENTICATED_USER',
  HALLON_TRIGGER_BANKID = 'HALLON_TRIGGER_BANKID',
  HALLON_TRIGGER_BANKID_PROMPT = 'HALLON_TRIGGER_BANKID_PROMPT',
  HALLON_EXISTING_CUSTOMER_IFRAME_ERROR = 'HALLON_EXISTING_CUSTOMER_IFRAME_ERROR',
  HALLON_LOGIN_HEIGHT = 'HALLON_LOGIN_HEIGHT',
  HALLON_LOGIN_ROUTE_TO_CREATE_NEW_PASSWORD = 'HALLON_LOGIN_ROUTE_TO_CREATE_NEW_PASSWORD',
  HALLON_LOGIN_IFRAME_READY = 'HALLON_LOGIN_IFRAME_READY',
}

export enum PaymentType {
  UPDATE_CARD = 'UpdateCard',
  PURCHASE = 'Purchase',
  REGISTER_ACCOUNT = 'RegisterAccount',
  CHECKOUT = 'Checkout',
}

export const InvoiceReloadAfterMilliseconds = 30 * 60 * 1000; // 30 minutes

export enum Device {
  'MOBILE' = 'mobile',
  'TABLET' = 'tablet',
  'DESKTOP' = 'desktop',
}

export enum PageKey {
  Abonnemang = 'abonnemang',
  Abonnemangsdetalj = 'abonnemangsdetalj',
  Addsurf = 'addsurf',
  Aktiveraesim = 'aktiveraesim',
  Avsluta = 'avsluta',
  Bestallsim = 'bestallsim',
  Betalnigar = 'betalningar',
  Bytabonnemang = 'bytabonnemang',
  Cancelled = 'cancelled',
  Extrausers = 'extrausers',
  ExtrauserSales = 'extra-anvandare',
  Index = 'index',
  Kontoinstallningar = 'kontoinstallningar',
  Konton = 'konton',
  Kvitto = 'kvitto',
  Login = 'login',
  Paus = 'paus',
}

export const PageSlugPrefix = 'pageSlug';

export const UseRedisCacheHeader = 'x-use-redis-cache';

export const RequestOriginHeaders = {
  'X-Hallon-Request-Origin': 'web.client',
};

export const AutoLogin = 'autoLogin';

export enum FontWeight {
  SemiBold = '600',
  Medium = '500',
  Regular = '400',
}

export enum IframeLoginSrc {
  CheckoutLogin = '/identify/checkout',
  HardwareLogin = '/identify/hardware',
}

export enum OrderType {
  SalesFlow = 'SalesFlow',
  Checkout = 'Checkout',
}

export const NonBreakingSpace = '\u00A0';
