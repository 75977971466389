import type { ActionPageInformationBlock, BlockListItem, ErrorMessage, FaqBlock, Modal, Product } from '@/types';

export type ExtraSurfAddOn = {
  $type: 'ExtraSurfAddOn';
  productDescription: string;
  productName: string;
  reference: string;
  regularPrice: string;
  id: number;
  name: string;
  url: string;
};

export enum ExtraSurfCmsErrorReference {
  GENERIC = 'GENERIC',
  ERROR_USER_IS_BLACKLISTED = 'ERROR_USER_IS_BLACKLISTED',
  ERROR_SUBSCRIPTION_NOT_FOUND = 'ERROR_SUBSCRIPTION_NOT_FOUND',
  ERROR_PAYMENT_METHOD_INVOICE_NOT_ALLOWED = 'ERROR_PAYMENT_METHOD_INVOICE_NOT_ALLOWED',
  ERROR_TOPUP_NOT_FOUND = 'ERROR_TOPUP_NOT_FOUND',
  RATOR_ERROR_TRANSACTION_AUTHORIZATION_ERROR = 'RATOR_ERROR_TRANSACTION_AUTHORIZATION_ERROR',
  RATOR_ERROR_ADYEN_PAYMENT_ERROR = 'RATOR_ERROR_ADYEN_PAYMENT_ERROR',
  RATOR_ERROR_TRANSACTION_EXCEPTION = 'RATOR_ERROR_TRANSACTION_EXCEPTION',
  RATOR_ERROR_TOPUP_FAILED = 'RATOR_ERROR_TOPUP_FAILED',
}

export type ExtraSurfCmsData = {
  $type: 'ExtraSurf';
  b2BpaymentInfo: string;
  b2BbroadbandExtraSurf: ExtraSurfAddOn[];
  b2BvoiceExtraSurf: ExtraSurfAddOn[];
  b2CbroadbandExtraSurf: ExtraSurfAddOn[];
  b2CvoiceExtraSurf: ExtraSurfAddOn[];
  b2CbroadbandNonContractExtraSurf: ExtraSurfAddOn[];
  b2CvoiceNonContractExtraSurf: ExtraSurfAddOn[];
  b2CcontractPaymentInfo: string;
  b2CnonContractPaymentInfo: string;
  blocks: BlockListItem<FaqBlock>[];
  confirmationButtonText: string;
  errors: BlockListItem<ErrorMessage<ExtraSurfCmsErrorReference>>[];
  information: BlockListItem<ActionPageInformationBlock>[];
  lineItemPrice: string;
  pageHeader: string;
  subscriptionLink: string;
  upgradeSubscriptionPrompt: string;
  successModals: BlockListItem<Modal>[];
  id: number;
  name: string;
  url: string;
  metaData: {
    productData: Product[];
  };
};
