import { ApplicationInsights, Snippet } from '@microsoft/applicationinsights-web';

const BANNERFLOW_RESIZE_OBSERVER_ERROR_MESSAGE =
  'ErrorEvent: ResizeObserver loop completed with undelivered notifications.';
const ignoredErrorMessages = [BANNERFLOW_RESIZE_OBSERVER_ERROR_MESSAGE];

export const interceptClientConsole = (appInsights: ApplicationInsights) => {
  /* eslint-disable no-console */
  const originalConsoleLog = console.log;
  const originalConsoleWarn = console.warn;
  const originalConsoleError = console.error;

  const telemetryLogger = (severity: 'INFO' | 'WARN' | 'ERROR', args: unknown[]) => {
    try {
      const message = args.map((arg) => (typeof arg === 'object' ? JSON.stringify(arg) : arg)).join(' ');

      let severityLevel = 1; // Default to INFO
      if (severity === 'ERROR') {
        severityLevel = 3;
      } else if (severity === 'WARN') {
        severityLevel = 2;
      }

      appInsights.trackTrace({
        message,
        severityLevel,
        properties: { clientSide: true, severity },
      });
    } catch (err) {
      originalConsoleError(`[ERROR: TelemetryLogger] ${err}`);
    }
  };

  console.log = (...args: unknown[]) => {
    telemetryLogger('INFO', args);
    originalConsoleLog(...args);
  };

  console.warn = (...args: unknown[]) => {
    telemetryLogger('WARN', args);
    originalConsoleWarn(...args);
  };

  console.error = (...args: unknown[]) => {
    telemetryLogger('ERROR', args);
    originalConsoleError(...args);
  };
  /* eslint-enable no-console */
};

export const setupAppInsights = (pathname: string) => {
  if (window.appInsights) {
    // eslint-disable-next-line no-console
    console.warn('App Insights is already initialized. Skipping reinitialization.');
    return;
  }

  const config: Snippet['config'] = {
    connectionString: process.env.APPLICATION_INSIGHT_CONNECTION_STRING,
    enableAutoRouteTracking: true,
  };

  if (pathname === '/identify/login') {
    config.correlationHeaderExcludedDomains = ['*'];
  }

  const appInsights = new ApplicationInsights({ config });
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((envelope) => {
    if (ignoredErrorMessages.includes(envelope.data?.message)) {
      return false;
    }

    /* eslint-disable no-param-reassign */
    if (envelope && envelope.tags) {
      envelope.tags['ai.cloud.role'] = 'Web.Client';
      envelope.tags['ai.cloud.roleName'] = 'Web.Client';
      envelope.tags['ai.cloud.roleInstance'] = window.location.hostname || 'localhost';
    }

    if (typeof window !== 'undefined') {
      envelope.data = envelope.data || {};
      envelope.data.isApp = window.isApp || false;
      envelope.data.rnHallonAppCode = window.rnHallonAppCode || '';
      envelope.data.appPlatform = window.appPlatform || 'web';
    }
    /* eslint-enable no-param-reassign */

    return true;
  });

  window.appInsights = appInsights;
  interceptClientConsole(appInsights);
};
