import { MappedUsage, Subscription, UnlimitedDataPackage } from '@/types';

const DATA_AMOUNT_UNLIMITED = 1073741824000000;

export const checkHasUnlimitedData = (dataAmount?: number) => {
  return dataAmount === DATA_AMOUNT_UNLIMITED;
};

function checkHasUnlimitedDataPackage(unlimitedDataPackage?: UnlimitedDataPackage) {
  if (!unlimitedDataPackage) {
    return { hasUnlimitedDataPackage: false, unlimitedPackageLessThanTwentyPercentLeft: false };
  }
  const hasUnlimitedDataPackage = unlimitedDataPackage?.validTo
    ? new Date(unlimitedDataPackage.validTo).getTime() > new Date().getTime()
    : false;

  const unlimitedPackageTotalDuration =
    unlimitedDataPackage?.validTo &&
    unlimitedDataPackage?.validFrom &&
    new Date(unlimitedDataPackage?.validTo).getTime() - new Date(unlimitedDataPackage?.validFrom).getTime();
  const unlimitedPackageRemainingDuration =
    unlimitedDataPackage?.validTo && new Date(unlimitedDataPackage?.validTo).getTime() - new Date().getTime();

  const unlimitedPackageLessThanTwentyPercentLeft =
    unlimitedPackageRemainingDuration && unlimitedPackageTotalDuration
      ? (unlimitedPackageRemainingDuration / unlimitedPackageTotalDuration) * 100 <= 20
      : false;
  return { hasUnlimitedDataPackage, unlimitedPackageLessThanTwentyPercentLeft };
}

export const getRandom = (numOfDigits: number) => {
  // eslint-disable-next-line no-bitwise
  const removeDecimals = (value: number) => ~~value;

  if (numOfDigits > 1) {
    const value = numOfDigits - 1;
    return Math.abs(removeDecimals(1 * 10 ** value + Math.random() * (1 * 10 ** (value + 1) - 1 - 1 * 10 ** value)));
  }
  return Math.abs(removeDecimals(Math.random() * 9));
};

export const mapUsageObject = (subscription: Pick<Subscription, 'usage' | 'unlimitedDataPackage'>): MappedUsage => {
  const { usage, unlimitedDataPackage } = subscription;
  const {
    dataTotal = 1,
    rollOverDataTotal = 1,
    dataRemaining = 0,
    euLimitRemaining = 0,
    euLimitTotal = 0,
    hasEuLimitBeenConsumed = false,
    currentPackageDataTotal = 0,
    rollOverDataRemaining = 0,
  } = usage ?? {};
  const isUnlimited = checkHasUnlimitedData(currentPackageDataTotal);
  const { hasUnlimitedDataPackage, unlimitedPackageLessThanTwentyPercentLeft } =
    checkHasUnlimitedDataPackage(unlimitedDataPackage);
  return {
    isUnlimited,
    hasUnlimitedDataPackage,
    unlimitedValidTo: unlimitedDataPackage?.validTo,
    isUnlimitedCloseToExpiry: unlimitedPackageLessThanTwentyPercentLeft,
    total: dataTotal + rollOverDataTotal,
    remaining: dataRemaining + rollOverDataRemaining,
    euLimitRemaining,
    hasEuLimitBeenConsumed,
    euLimitTotal,
    currentPackageDataTotal,
  };
};

export const mapUsageForExtraUser = (
  parentSubscription: Pick<Subscription, 'usage' | 'unlimitedDataPackage'>,
  extraSimSubscription: Pick<Subscription, 'usage' | 'unlimitedDataPackage'>
): MappedUsage => {
  const {
    dataTotal = 0,
    rollOverDataTotal = 0,
    dataRemaining = 0,
    rollOverDataRemaining = 0,
    currentPackageDataTotal = 0,
  } = parentSubscription.usage ?? {};
  const { euLimitRemaining = 0, euLimitTotal = 0, hasEuLimitBeenConsumed = false } = extraSimSubscription.usage ?? {};
  const isUnlimited = checkHasUnlimitedData(currentPackageDataTotal);
  const {
    hasUnlimitedDataPackage: parentHasUnlimitedDataPackage,
    unlimitedPackageLessThanTwentyPercentLeft: parentUnlimitedPackageLessThanTwentyPercentLeft,
  } = checkHasUnlimitedDataPackage(parentSubscription.unlimitedDataPackage);

  return {
    isUnlimited,
    hasUnlimitedDataPackage: parentHasUnlimitedDataPackage,
    unlimitedValidTo: parentSubscription.unlimitedDataPackage?.validTo,
    isUnlimitedCloseToExpiry: parentUnlimitedPackageLessThanTwentyPercentLeft,
    total: dataTotal + rollOverDataTotal,
    remaining: dataRemaining + rollOverDataRemaining,
    euLimitRemaining,
    hasEuLimitBeenConsumed,
    euLimitTotal,
    currentPackageDataTotal,
  };
};

export const roundValue = (value: number, precision?: number) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const bytesToTb = (bytes: number) => {
  return roundValue(bytes / 1099511627776, 2);
};

export const bytesToGb = (bytes: number) => {
  return roundValue(bytes / 1073741824, 1);
};

export const bytesToMb = (bytes: number) => {
  return roundValue(bytes / 1048576, 0);
};

export const bytesToKb = (bytes: number) => {
  return roundValue(bytes / 1024, 0);
};

export const formatDecimalNumber = (number: number) => {
  const textNumber = `${number}`;
  return textNumber.replace('.', ',');
};

export const surfDataPretty = (bytes: number) => {
  const MaxGigabyteDecimalNumber = 5;

  switch (true) {
    case bytes >= 1000000000000:
      return `${bytesToTb(bytes)} TB`;
    case bytes >= 1000000000: {
      const gigabyte = bytesToGb(bytes);
      // Will format decimal number with comma for GB under MaxGigabyteDecimalNumber
      return `${gigabyte >= MaxGigabyteDecimalNumber ? roundValue(gigabyte) : formatDecimalNumber(gigabyte)} GB`;
    }
    case bytes >= 1000000:
      return `${bytesToMb(bytes)} MB`;
    case bytes >= 1000:
      return `${bytesToKb(bytes)} KB`;
    default:
      return `${bytes ?? 0} Bytes`;
  }
};

export const timePretty = (minutes: number) => {
  if (minutes >= 60) {
    const val = minutes / 60;
    return `${val}&nbsp;${val === 1 ? 'timme' : 'timmar'}`.replace('.', ',');
  }
  return `${minutes}&nbsp;minuter`;
};

export const gbToVideoTime = (gb: number) => {
  return timePretty(gb * 30);
};

export const gbToMusicTime = (gb: number) => {
  return timePretty(gb * 2 * 60);
};

export const getAmountGbNumber = (dataAmount: number) => {
  const prettied = surfDataPretty(dataAmount);
  const amountNumberSplit = prettied.match(/\d+/);
  if (amountNumberSplit && amountNumberSplit[0]) {
    const amountNumber = amountNumberSplit[0];
    return parseInt(amountNumber, 10);
  }

  return 0;
};
