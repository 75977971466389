'use client';

import { CSSProperties, ReactNode } from 'react';

import { HallonColor } from '@/global/utils/styleTypes';

import classNames from 'classnames';
import { filterUndefinedValues } from '@/global/utils/object';
import styles from './HtmlFromCMS.module.css';

type HtmlFromCMSProps = {
  html?: ReactNode;
  linkColor?: HallonColor;
  color?: HallonColor;
  richText?: boolean;
  'data-testid'?: string;
  className?: string;
};
const HtmlFromCMS = (props: HtmlFromCMSProps) => {
  const { html, richText = false, linkColor, 'data-testid': dataTestId, color, className } = props;
  if (!html) return null;

  const styleVariables = { '--link-color': linkColor, '--text-color': color } as CSSProperties;

  const htmlWithLinkClass = html.toString().replace(/<a/g, `<a class="${styles.cmsLink}"`);

  return (
    <div
      data-testid={dataTestId}
      className={classNames(styles.content, { [styles.richText]: richText }, className)}
      style={filterUndefinedValues(styleVariables)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: htmlWithLinkClass }}
    />
  );
};

export default HtmlFromCMS;
