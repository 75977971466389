import Spinner from '@/newComponents/Spinner/Spinner';
import { SpinnerSize } from '@/newComponents/Spinner/Spinner.utils';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';

import { Color } from '@/global/style/variables';

import {
  Button,
  ButtonInnerContent,
} from '@/components/AppHeader/AppDropDownMenuAccountButton/AppDropDownMenuAccountButton.styled';

type AppDropDownMenuAccountButtonType = {
  email: string;
  onClick: () => void;
  marked: boolean;
  isLoading?: boolean;
  isActive?: boolean;
};
const AppDropDownMenuAccountButton = (props: AppDropDownMenuAccountButtonType) => {
  const { email, onClick, marked, isLoading = false, isActive = false } = props;

  return (
    <Button onClick={onClick} $marked={marked && !isLoading} $isLoading={isActive && isLoading}>
      <ButtonInnerContent>
        {isActive && isLoading && (
          <div>
            <Spinner size={SpinnerSize.Mini} color={Color.Ocean} />
          </div>
        )}
        {marked && !isLoading && <SvgIcon icon="smallArrow" size="small" color={Color.Ocean} />}
        {email}
      </ButtonInnerContent>
    </Button>
  );
};

export default AppDropDownMenuAccountButton;
