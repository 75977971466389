import { RequestOriginHeaders } from '@/constants';
import { create } from 'gretchen';
import { NextApiRequest, NextApiResponse } from 'next';
import { getToken } from 'next-auth/jwt';

export const generateSecurePayload = (
  method: string,
  token?: string,
  body?: BodyInit | null,
  traceparent?: string,
  forwaredFor?: string
): RequestInit => {
  const payload = {
    method,
    headers: {
      ...RequestOriginHeaders,
      accept: '*/*',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      traceparent: traceparent ?? '',
      'x-forwarded-for': forwaredFor ?? '',
    },
    opentelemetry: {
      propagateContext: true,
    },
    signal: AbortSignal.timeout(35000),
    body,
  };

  return payload;
};

export const respondError = (response: NextApiResponse, statusCode: number, data?: unknown) => {
  const status = statusCode ?? 401;
  response.status(status).json({ type: 'error', status, data });
};

export const formatApiErrorResponse = (error: unknown) => {
  let errorMessage = 'Unknown error';
  let errorStatus = 500;

  // Check if error has an errorCode property
  if (error && typeof error === 'object' && 'errorCode' in error) {
    errorMessage = error?.errorCode as string;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  }

  // Check if error contains a status property
  if (error && typeof error === 'object' && 'status' in error) {
    errorStatus = error?.status as number;
  }

  return { status: errorStatus, message: errorMessage };
};

export const tokenizedApiCall = async (url: string, req: NextApiRequest) => {
  try {
    const token = await getToken({ req });

    const requestOptions = generateSecurePayload(
      req.method as string,
      token?.user?.access_token,
      req.method !== 'GET' ? JSON.stringify(req.body) : null,
      req.headers.traceparent as string,
      req.headers['x-forwarded-for'] as string
    );

    const response = await fetch(url, requestOptions);

    return response;
  } catch (error: unknown) {
    const { message, status } = formatApiErrorResponse(error);
    console.log('Error when calling tokenizedApiCall', message); // eslint-disable-line no-console

    return new Response(message, { status });
  }
};

type RetryParams = {
  attempts?: number;
  httpCodes?: number[];
  methods?: string[];
  delay?: number;
};

// gretch has a bug where the exponential delay is not calculated correctly.
// Using more attempts than 3 will cause the last attempt to be delayed for several minutes (when you're using the default delay of 6ms)
// https://github.com/truework/gretchen/issues/46
export const getGretchRetryPolicy = (retryParams: RetryParams) => {
  const {
    attempts = 3,
    httpCodes = [408, 429, 500, 502, 503, 504],
    delay = 6,
    methods = ['GET', 'POST', 'PUT', 'PATCH'],
  } = retryParams;
  return {
    attempts,
    codes: httpCodes,
    delay,
    methods,
  };
};

const handle401 = ({ status }: { status: number }) => {
  if (status === 401 && typeof window !== 'undefined') {
    window.location.href = '/api/auth/logout?backToMyPages=true';
  }
};

export const gretch = create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'GET',
  opentelemetry: {
    propagateContext: true,
  },
  timeout: 35000,
  hooks: {
    after: [handle401],
  },
  cache: 'no-store',
});
