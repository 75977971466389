import { GtmLineItem, GtmLoginMethod, GtmLoginType } from '@/types';
import { ReactNode } from 'react';

// Pushes the dataLayer with the data from below functions
export const setDataLayer = (data: Record<string, unknown>) => {
  const trackingData = data;
  if (window.rnHallonAppCode) {
    trackingData.app_version = window.rnHallonAppCode;
  }

  trackingData.platform_type = window.appPlatform || 'browser';
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push(trackingData);
  }
};

// recommended by google to push null to reset the ecommerce data before every ecommerce event
export const setDataLayerEcommerce = (data: Record<string, unknown>) => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({ ecommerce: null });
    setDataLayer(data);
  }
};

export const gtmAddSurf = (dataAmount: string) => {
  setDataLayer({
    event: 'buy data',
    dataSize: dataAmount,
  });
};

export const gtmLoginUser = (userId: string, paymentMethods: Record<string, unknown>) => {
  // Guard
  if (!paymentMethods) return;

  const customerType = paymentMethods.isInvoice ? 'Faktura kund' : 'Kort kund';

  setDataLayer({
    event: 'mina sidor login',
    userID: userId,
    customerType,
  });
};

export const gtmBlockSim = () => {
  setDataLayer({
    event: 'block sim card',
  });
};

export const gtmOrderSim = () => {
  setDataLayer({
    event: 'order new sim card',
  });
};

export const gtmSetPageView = (url: string, title: string) => {
  setDataLayer({
    event: 'pageview',
    page: {
      path: url,
      title,
    },
  });
};

export const gtmSetVirtualPageView = (url: string, title?: string) => {
  setDataLayer({
    event: 'virtualPageView',
    page: {
      path: url,
      title,
    },
  });
};

export const gtmException = (description: ReactNode) => {
  // Remove html tags from description
  if (!description) return null;

  const sanitizedDescription = description.toString().replace(/<\/?[^>]+(>|$)/g, '');
  setDataLayer({
    event: 'exception',
    description: sanitizedDescription,
  });
};

export const getGtmLineItemNamesAsString = (lineItems: GtmLineItem[]) => {
  return lineItems.map((lineItem) => lineItem.item_name).join(',');
};

/*
 * Checkout 2.0
 *
 * GTM events for Hallon Checkout 2.0
 */
export const gtmAddToCart = (_items: GtmLineItem[], value: number, promotionName?: string) => {
  let items = _items;
  if (promotionName) {
    items = _items.map((item) => ({ ...item, promotion_name: promotionName }));
  }

  setDataLayerEcommerce({
    event: 'add_to_cart',
    ecommerce: {
      items,
      value,
    },
  });
};

export const gtmViewCart = (items: GtmLineItem[], value: number) => {
  setDataLayerEcommerce({
    event: 'view_cart',
    ecommerce: {
      items,
      value,
    },
  });
};

export const gtmApplyVoucher = () => {
  setDataLayer({
    event: 'apply_voucher',
  });
};

export const gtmOpenVoucherModal = () => {
  setDataLayer({
    event: 'open_voucher_modal',
  });
};

export const gtmCloseVoucherModal = () => {
  setDataLayer({
    event: 'close_voucher_modal',
  });
};

export const gtmApplyVoucherModal = () => {
  setDataLayer({
    event: 'apply_voucher_modal',
  });
};

export const gtmRemoveVoucherModal = () => {
  setDataLayer({
    event: 'remove_voucher_modal',
  });
};

export const gtmBeginCheckout = (items: GtmLineItem[], value: number) => {
  setDataLayerEcommerce({
    event: 'begin_checkout',
    ecommerce: {
      items,
      value,
    },
  });
};

export const gtmLogin = (method: GtmLoginMethod, type: GtmLoginType) => {
  setDataLayer({
    event: 'login',
    method,
    type,
  });
};

export const gtmLogout = () => {
  setDataLayer({
    event: 'logout',
  });
};

export const gtmLoginFailure = (method: GtmLoginMethod | 'no_customer') => {
  setDataLayer({
    event: 'login_failure',
    method,
  });
};

export const gtmCheckoutAddShippingInfo = (
  shippingTier: 'parcel' | 'letter' | 'combo' = 'letter',
  items: GtmLineItem[],
  value: number
) => {
  setDataLayerEcommerce({
    event: 'add_shipping_info',
    ecommerce: {
      shipping_tier: shippingTier, // todo this property should be taken from BE post mvp
      items,
      value,
    },
  });
};

export const gtmCheckoutViewNumberInfo = (items: GtmLineItem[]) => {
  setDataLayer({
    event: 'view_number_info',
    items_details_info: {
      items_name_list: getGtmLineItemNamesAsString(items),
    },
  });
};

export const gtmCheckoutSelectNewNumber = () => {
  setDataLayer({
    event: 'select_new_number',
  });
};

export const gtmCheckoutSelectKeepNumber = () => {
  setDataLayer({
    event: 'select_keep_number',
  });
};

export const gtmCheckoutAddPaymentInfo = (items: GtmLineItem[], value: number, paymentType: 'card' | 'invoice') => {
  setDataLayerEcommerce({
    event: 'add_payment_info',
    ecommerce: {
      items,
      value,
      payment_type: paymentType,
    },
  });
};

export const gtmCheckoutConfirmOrder = () => {
  setDataLayer({
    event: 'confirm_order',
  });
};

export const gtmCheckoutConfirmOrderButtonClick = () => {
  setDataLayer({
    event: 'confirm_order_button_click',
  });
};

export const gtmCheckoutPurchase = (items: GtmLineItem[], value: number, tax: number, transactionId: string | null) => {
  setDataLayerEcommerce({
    event: 'purchase',
    ecommerce: {
      items,
      value,
      tax,
      transaction_id: transactionId,
    },
  });
};

export const gtmCheckoutSignup = (method: 'email', type: 'business' | 'consumer') => {
  setDataLayer({
    event: 'sign_up',
    method,
    type,
  });
};

export const gtmViewSummary = (items: GtmLineItem[]) => {
  setDataLayer({
    event: 'view_summary',
    items_details_info: {
      items_name_list: getGtmLineItemNamesAsString(items),
    },
  });
};

export const gtmViewCheckoutIdentificationPrompt = () => {
  setDataLayer({
    event: 'view_checkout_identification_prompt',
  });
};

export const gtmCheckoutIdentificationSuccess = () => {
  setDataLayer({
    event: 'checkout_identification_success',
  });
};

export const gtmCheckoutIdentificationFailure = () => {
  setDataLayer({
    event: 'checkout_identification_failure',
  });
};

/* End Checkout 2.0  */

/**
 * Cancel subscription
 */

export const gtmCancelExitLink = (step: string, linkText: string) => {
  setDataLayer({
    event: 'abandon_cancellation_flow',
    step,
    link_text: linkText,
  });
};

export const gtmCancelStepButton = (buttonText: string, reason?: string) => {
  setDataLayer({
    event: 'cancel_step_button',
    buttonText,
    reason,
  });
};

/**
 * Item
 */

export const gtmViewItem = (item: GtmLineItem | null) => {
  const { currency, price, discount = 0 } = item || {};
  const totalPrice = price || 0;
  setDataLayerEcommerce({
    event: 'view_item',
    ecommerce: {
      currency,
      value: totalPrice - discount,
      items: [item],
    },
  });
};
/**
 * Item list
 */

export const gtmViewItemList = (itemListName: string, items: GtmLineItem[] | null) => {
  setDataLayerEcommerce({
    event: 'view_item_list',
    ecommerce: {
      item_list_name: itemListName,
      items,
    },
  });
};

export const gtmSelectItemListItem = (item: GtmLineItem | null) => {
  setDataLayerEcommerce({
    event: 'select_item',
    ecommerce: {
      items: [item],
    },
  });
};

/**
 * Promotions
 */

export const gtmViewPromotion = (creativeName: string, promotionName: string, item: GtmLineItem | null = null) => {
  if (!item) {
    setDataLayerEcommerce({
      event: 'view_non_ecom_promotion',
      ecommerce: {
        creative_name: creativeName,
        promotion_name: promotionName,
      },
    });
  } else {
    setDataLayerEcommerce({
      event: 'view_promotion',
      ecommerce: {
        creative_name: creativeName,
        promotion_name: promotionName,
        items: [item],
      },
    });
  }
};

export const gtmSelectPromotion = (creativeName: string, promotionName: string, item: GtmLineItem | null = null) => {
  if (!item) {
    setDataLayerEcommerce({
      event: 'select_non_ecom_promotion',
      ecommerce: {
        creative_name: creativeName,
        promotion_name: promotionName,
      },
    });
  } else {
    setDataLayerEcommerce({
      event: 'select_promotion',
      ecommerce: {
        creative_name: creativeName,
        promotion_name: promotionName,
        items: [item],
      },
    });
  }
};

/**
 * Hardware specifics
 */

export const gtmSelectHardwareVariant = () => {
  setDataLayer({
    event: 'select_hardware_variant',
  });
};

/**
 * Change subscription
 */

export const gtmChangeSubscription = (currentSubscription: string, newSubscription: string) => {
  setDataLayer({
    event: 'change_subscription',
    current_subscription: currentSubscription,
    new_subscription: newSubscription,
  });
};
