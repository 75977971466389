import { HallonSvgIconProps } from '@/types/icon';
import classNames from 'classnames';
import styles from './BaseSvg.module.css';

type SvgWithTitleProps = {
  children: React.ReactNode;
} & React.SVGProps<SVGSVGElement> &
  HallonSvgIconProps;

const SvgWithTitle = ({
  title: _title,
  ariaHidden: _ariaHidden,
  'aria-describedby': ariaDescribedBy,
  className: _className,
  children,
  ...rest
}: SvgWithTitleProps) => {
  const ariaHidden = _ariaHidden || rest['aria-hidden'];
  const title = _title && !ariaHidden && !ariaDescribedBy ? _title : undefined;
  const className = classNames(styles.baseSvg, _className);
  return (
    <svg
      role="img"
      aria-labelledby={title}
      aria-hidden={ariaHidden}
      aria-describedby={ariaDescribedBy}
      className={className}
      {...rest}
    >
      {title && <title id={title}>{title}</title>}
      {children}
    </svg>
  );
};

export default SvgWithTitle;
