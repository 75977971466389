import(/* webpackMode: "eager" */ "/src/node_modules/.pnpm/next@14.2.22_@babel+core@7.26.0_supports-color@8.1.1__@opentelemetry+api@1.7.0_react-dom@18.3_vwlg7ztycha2l7g2bwnhqyesou/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/src/public/images/placeholder1.webp");
;
import(/* webpackMode: "eager" */ "/src/public/images/placeholder2.webp");
;
import(/* webpackMode: "eager" */ "/src/public/images/placeholder3.webp");
;
import(/* webpackMode: "eager" */ "/src/public/images/placeholder4.webp");
;
import(/* webpackMode: "eager" */ "/src/public/images/placeholder5.webp");
;
import(/* webpackMode: "eager" */ "/src/src/app/_lib/PageWrappers/SlugPages/ArticlePage/ArticlePage.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/app/_lib/PageWrappers/SlugPages/Savedesk/SavedeskPage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tracking"] */ "/src/src/app/_lib/tracking.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/components/ErrorBoundary/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/global/utils/HtmlFromCMS.tsx");
;
import(/* webpackMode: "eager" */ "/src/src/newComponents/CmsBlocks/BlockList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/newComponents/Divider/Divider.tsx");
;
import(/* webpackMode: "eager" */ "/src/src/newComponents/Typography/Typography.module.css");
