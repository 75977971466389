import { ApiUrl, UseRedisCacheHeader } from '@/constants';
import { StickerVariant } from '@/newComponents/Sticker/StickerTypes';
import {
  BlockListItem,
  BlockWithStickerCmsTheme,
  BlockWithStickerCmsThemeKey,
  ChangeSubscriptionCmsData,
  ChangeSubscriptionProductSection,
  CountdownCmsTheme,
  CountdownCmsThemeKey,
  ErrorMessageWrappedInContent,
  FetchCmsContentItem,
  MiniTeasersCmsTheme,
  MiniTeasersCmsThemeKey,
  NeutralCmsTheme,
  NeutralCmsThemeKey,
  Notice,
  UspCmsTheme,
  UspCmsThemeKey,
} from '@/types';
import { ParsedUrlQuery } from 'querystring';

import { Color } from '@/global/style/variables';
import { accordionSectionTypeToCmsProductTypeMapper } from '@/global/utils/accordionSectionTypeToCmsProductTypeMapper';

import {
  AccordionSectionType,
  CustomerAndProductCombo,
} from '@/pagewrappers/ChangeSubscription/ChangeSubscription.types';

function objectToQueryString(params: Record<string, string | string[] | number | boolean>): string {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    queryParams.append(key, String(value));
  });

  return queryParams.toString();
}

export const fetchCmsContent = async <T extends unknown[]>(
  cmsRoutes: FetchCmsContentItem[],
  query: ParsedUrlQuery
): Promise<T> => {
  const disableCache = query.cache === 'false';

  const promises = cmsRoutes.map(async (route) => {
    const { key, suffix = '', queryParams = {} } = route;
    const queryString = objectToQueryString(queryParams);
    let url = `${ApiUrl.webClientInternalApi}/cms/${key}${suffix}`;
    if (queryString) {
      url += `?${queryString}`;
    }

    const response = await fetch(url, {
      headers: {
        [UseRedisCacheHeader]: disableCache ? 'false' : 'true',
      },
      cache: 'no-store',
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch CMS content for route: ${JSON.stringify(route)}, ${url}`);
    }

    const cmsData = await response.json();
    return cmsData;
  });

  return (await Promise.all(promises)) as T;
};

export const getCmsTextWithValues = (text = '', values?: Record<string, string>): string => {
  if (!text) return '';
  let htmlText = text;

  // Replace **value** with <b>value</b>
  const boldRegex = /\*\*([^*]+)\*\*/g;
  htmlText = htmlText.replace(boldRegex, '<b>$1</b>');

  // Replace ~~value~~ with <s>value</s>
  const strikeThroughRegex = /~~([^~]+)~~/g;
  htmlText = htmlText.replace(strikeThroughRegex, '<s>$1</s>');

  if (values) {
    // Matches: %<matchedValue>%
    const valueReplaceRegex = /%([^%]+)%/g;

    // _ is curried regex
    const replacer = (_: string, keyInText: string) => {
      const hasTextAndValueMatch = values[keyInText] != null;
      return hasTextAndValueMatch ? values[keyInText] : `%${keyInText}%`;
    };

    htmlText = htmlText.replace(valueReplaceRegex, replacer);
  }

  return htmlText;
};

export const getCmsErrorByReference = (
  cmsErrors: ErrorMessageWrappedInContent<string>[],
  errorReferenceKey: string
) => {
  const cmsError = cmsErrors.find((error) => error.content.referenceKey === errorReferenceKey);
  if (cmsError) return cmsError.content.body;

  // eslint-disable-next-line no-console
  console.log('Failed to find the cmsError by referenceKey: ', errorReferenceKey);
  return 'Ett oväntat fel uppstod';
};

export const getCmsErrorObjectByReference = ({
  cmsErrors,
  errorReferenceKey,
}: {
  cmsErrors?: ErrorMessageWrappedInContent<string>[];
  errorReferenceKey: string;
}) => {
  const cmsError = cmsErrors?.find((error) => error.content.referenceKey === errorReferenceKey);
  if (cmsError) return cmsError;

  // eslint-disable-next-line no-console
  console.log('Failed to find the cmsError by referenceKey: ', errorReferenceKey);
  return null;
};

export const getCmsErrorAsNotice = (
  cmsError: ErrorMessageWrappedInContent<string> | null,
  fallBackHeader = true
): Notice => {
  let header = cmsError?.content?.header;
  if (!header) {
    header = fallBackHeader ? 'Något gick fel' : '';
  }

  const description = cmsError?.content?.body || '';

  const notice: Notice = {
    header,
    description,
    text: description,
    reference: cmsError?.content?.referenceKey || '',
    buttonText: cmsError?.content?.buttonText || '',
  };

  if (cmsError?.content?.buttonLink) {
    const link = cmsError.content.buttonLink;
    notice.buttonOnClick = () => {
      window.location.href = link;
    };
  }

  return notice;
};

export const getCmsErrorAsNoticeWithButtonLink = (
  cmsError: ErrorMessageWrappedInContent<string> | null,
  fallBackHeader = true
): Notice => {
  const baseNotice = getCmsErrorAsNotice(cmsError, fallBackHeader);

  return {
    ...baseNotice,
    buttonHref: cmsError?.content.buttonLink || '',
  };
};

type ArbitraryObject = {
  referenceKey: string;
} & Record<string, unknown>;

// Get a matching object by reference from a list of arbitrary cms objects
export const getCmsContentByReference = (
  cmsObjects: Pick<BlockListItem<ArbitraryObject>, 'content'>[],
  referenceKey: string
) => {
  const object = cmsObjects.find((cmsObject) => cmsObject.content.referenceKey === referenceKey);
  if (object) return object;

  return null;
};

export const getAccordionProductSectionContentBySectionKeyAndType = (
  accordionProductSectionKey: CustomerAndProductCombo | null,
  sectionType: AccordionSectionType,
  changeSubscriptionCmsData: ChangeSubscriptionCmsData | null
): ChangeSubscriptionProductSection | undefined => {
  const accordionProductSections = changeSubscriptionCmsData?.[
    accordionProductSectionKey as CustomerAndProductCombo
  ] as BlockListItem<ChangeSubscriptionProductSection>[];

  return accordionProductSections?.find((item) => {
    return item.content.productType === accordionSectionTypeToCmsProductTypeMapper(sectionType);
  })?.content;
};

export const getBlockWithStickerCmsTheme = (cmsThemeKey?: BlockWithStickerCmsThemeKey): BlockWithStickerCmsTheme => {
  switch (cmsThemeKey) {
    case BlockWithStickerCmsThemeKey.LightHallon:
      return {
        backgroundColor: Color.Hallon5,
        foregroundColor: Color.Hallon1,
        stickerVariant: StickerVariant.Dark,
        linkColor: Color.Hallon1,
        transparentSecondaryButton: false,
      };
    case BlockWithStickerCmsThemeKey.DarkHallon:
      return {
        backgroundColor: Color.Hallon1,
        foregroundColor: Color.Hallon5,
        stickerVariant: StickerVariant.Light,
        linkColor: Color.White,
        transparentSecondaryButton: true,
      };
    case BlockWithStickerCmsThemeKey.Image:
      return {
        backgroundColor: Color.Hallon1,
        foregroundColor: Color.White,
        stickerVariant: StickerVariant.Light,
        linkColor: Color.White,
        transparentSecondaryButton: true,
      };
    case BlockWithStickerCmsThemeKey.UsedHardware:
      return {
        backgroundColor: Color.Nyanza,
        foregroundColor: Color.Hallon1,
        stickerVariant: StickerVariant.Light,
        linkColor: Color.White,
        transparentSecondaryButton: true,
        textColorSecondaryButton: Color.Hallon1,
      };

    case BlockWithStickerCmsThemeKey.White:
    default:
      return {
        backgroundColor: Color.White,
        foregroundColor: Color.MineShaft,
        stickerVariant: StickerVariant.Light,
        linkColor: Color.Blue,
        transparentSecondaryButton: false,
      };
  }
};

export const getNeutralCmsTheme = (cmsThemeKey?: NeutralCmsThemeKey): NeutralCmsTheme => {
  switch (cmsThemeKey) {
    case NeutralCmsThemeKey.LightHallon:
      return {
        backgroundColor: Color.Hallon6,
        foregroundColor: Color.MineShaft,
        linkColor: Color.Blue,
        transparentSecondaryButton: false,
      };
    case NeutralCmsThemeKey.DarkHallon:
      return {
        backgroundColor: Color.Hallon1,
        foregroundColor: Color.White,
        linkColor: Color.White,
        transparentSecondaryButton: true,
      };

    case NeutralCmsThemeKey.White:
    default:
      return {
        backgroundColor: Color.White,
        foregroundColor: Color.MineShaft,
        linkColor: Color.Blue,
        transparentSecondaryButton: false,
      };
  }
};

export const getUspCmsTheme = (cmsThemeKey?: UspCmsThemeKey): UspCmsTheme => {
  switch (cmsThemeKey) {
    case UspCmsThemeKey.White:
      return {
        backgroundColor: Color.White,
        foregroundColor: Color.MineShaft,
      };
    case UspCmsThemeKey.Gray:
    default:
      return {
        backgroundColor: Color.Gray3,
        foregroundColor: Color.MineShaft,
      };
  }
};

export const getCountdownCmsTheme = (cmsThemeKey?: CountdownCmsThemeKey): CountdownCmsTheme => {
  switch (cmsThemeKey) {
    case CountdownCmsThemeKey.MineShaft:
      return {
        backgroundColor: Color.MineShaft,
        foregroundColor: Color.White,
        linkColor: Color.White,
        transparentSecondaryButton: true,
      };
    case CountdownCmsThemeKey.Gray:
      return {
        backgroundColor: Color.Gray3,
        foregroundColor: Color.MineShaft,
        linkColor: Color.Blue,
        transparentSecondaryButton: false,
      };
    case CountdownCmsThemeKey.UsedHardware:
      return {
        backgroundColor: Color.Nyanza,
        foregroundColor: Color.Hallon1,
        linkColor: Color.White,
        transparentSecondaryButton: true,
      };
    case CountdownCmsThemeKey.White:
      return {
        backgroundColor: Color.White,
        foregroundColor: Color.MineShaft,
        linkColor: Color.Blue,
        transparentSecondaryButton: false,
      };
    case CountdownCmsThemeKey.LightHallon:
      return {
        backgroundColor: Color.Hallon5,
        foregroundColor: Color.Hallon1,
        linkColor: Color.Hallon1,
        transparentSecondaryButton: false,
      };
    case CountdownCmsThemeKey.DarkHallon:
    default:
      return {
        backgroundColor: Color.Hallon1,
        foregroundColor: Color.White,
        linkColor: Color.White,
        transparentSecondaryButton: true,
      };
  }
};

export const getMiniTeasersCmsTheme = (cmsThemeKey?: MiniTeasersCmsThemeKey): MiniTeasersCmsTheme => {
  switch (cmsThemeKey) {
    case MiniTeasersCmsThemeKey.Mixed:
      return {
        backgroundColor: Color.Gray2,
        items: [
          {
            foregroundColor: Color.White,
            backgroundColor: Color.Hallon2,
          },
          {
            foregroundColor: Color.Hallon1,
            backgroundColor: Color.Gray3,
          },
          {
            foregroundColor: Color.Hallon1,
            backgroundColor: Color.Lemon3,
          },
          {
            foregroundColor: Color.Hallon1,
            backgroundColor: Color.Hallon6,
          },
        ],
      };
    case MiniTeasersCmsThemeKey.DarkHallon:
      return {
        backgroundColor: Color.Gray2,
        items: [
          {
            foregroundColor: Color.Hallon6,
            backgroundColor: Color.Hallon2,
          },
          {
            foregroundColor: Color.Hallon6,
            backgroundColor: Color.Hallon2,
          },
          {
            foregroundColor: Color.Hallon6,
            backgroundColor: Color.Hallon2,
          },
          {
            foregroundColor: Color.Hallon6,
            backgroundColor: Color.Hallon2,
          },
        ],
      };
    case MiniTeasersCmsThemeKey.Gray:
    default:
      return {
        backgroundColor: Color.Gray2,
        items: [
          {
            foregroundColor: Color.MineShaft,
            backgroundColor: Color.Gray3,
          },
          {
            foregroundColor: Color.MineShaft,
            backgroundColor: Color.Gray3,
          },
          {
            foregroundColor: Color.MineShaft,
            backgroundColor: Color.Gray3,
          },
          {
            foregroundColor: Color.MineShaft,
            backgroundColor: Color.Gray3,
          },
        ],
      };
  }
};
