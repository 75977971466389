'use client';

import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { Color } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import {
  Container,
  HeaderContainer,
  IconWrapper,
  InnerBody,
  MotionWrapper,
  TextAndIconWrapper,
  ToggleInfoHoverVariant,
} from './ToggleInfo.styled';

export type ToggleInfoProps = {
  ariaLabel?: string;
  ['data-testid']: string;
  header: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  headerExpanded?: React.ReactNode;
  defaultExpanded?: boolean;
  expansionCallback?: (expanded: boolean) => void;
  withBackground?: boolean;
  hoverVariant?: ToggleInfoHoverVariant;
};

const ToggleInfo = (props: ToggleInfoProps) => {
  const {
    ariaLabel,
    children,
    header,
    className = '',
    disabled = false,
    headerExpanded = header,
    defaultExpanded = false,
    withBackground = true,
    expansionCallback,
    hoverVariant = ToggleInfoHoverVariant.MineShaft,
  } = props;
  const [expanded, setExpanded] = useState(defaultExpanded);

  useEffect(() => {
    if (disabled) setExpanded(false);
  }, [disabled]);

  return (
    <Container data-testid={props['data-testid']} className={className}>
      <TextAndIconWrapper
        $hoverVariant={hoverVariant}
        $withBackround={withBackground}
        $disabled={disabled}
        $expanded={expanded}
        aria-expanded={expanded}
        aria-controls="toggle-info-content"
        aria-label={ariaLabel && expanded ? ariaLabel : undefined}
        onClick={() => {
          if (!disabled) {
            expansionCallback?.(!expanded);
            setExpanded(!expanded);
          }
        }}
      >
        <HeaderContainer $disabled={disabled}>
          <Typography type={TypographyType.Body} fontWeight={FontWeight.Medium}>
            {expanded ? headerExpanded : header}
          </Typography>
        </HeaderContainer>

        <IconWrapper initial={false} transition={{ type: 'linear' }} animate={{ rotate: expanded ? 180 : 0 }}>
          <SvgIcon icon="chevron-down" size="small" color={disabled ? Color.DarkGray2 : Color.MineShaft} />
        </IconWrapper>
      </TextAndIconWrapper>
      <MotionWrapper>
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.div
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { height: 'auto', transition: { duration: 0.3 } },
                collapsed: { height: 0, transition: { duration: 0 } },
              }}
            >
              <InnerBody id="toggle-info-content">{children}</InnerBody>
            </motion.div>
          )}
        </AnimatePresence>
      </MotionWrapper>
    </Container>
  );
};

export default ToggleInfo;
