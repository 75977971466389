'use client';

import { Color } from '@/global/style/variables';
import { HallonColor } from '@/global/utils/styleTypes';

import { SpinnerContainer } from './Spinner.styled';
import { presets, SpinnerSize } from './Spinner.utils';

export type SpinnerProps = {
  size?: SpinnerSize;
  className?: string;
  color?: HallonColor;
};

const Spinner = (props: SpinnerProps) => {
  const { className, size = SpinnerSize.Medium, color = Color.Ocean } = props;
  const { diameter, strokeWidth, haloWidth, haloSize, haloOffset } = presets[size];

  return (
    <SpinnerContainer
      className={className}
      $size={diameter}
      $strokeWidth={strokeWidth}
      $color={color}
      $haloWidth={haloWidth}
      $haloSize={haloSize}
      $haloOffset={haloOffset}
    >
      <svg className="circular-loader" viewBox="25 25 50 50">
        <circle
          className="loader-path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        />
      </svg>
    </SpinnerContainer>
  );
};

export default Spinner;
