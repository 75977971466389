export enum SpinnerSize {
  Mini = 'mini',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export const presets = {
  [SpinnerSize.Mini]: {
    diameter: 18,
    strokeWidth: 5,
    haloWidth: 1.5,
    haloSize: 16,
    haloOffset: 1,
  },
  [SpinnerSize.Small]: {
    diameter: 28,
    strokeWidth: 4,
    haloWidth: 2,
    haloSize: 24,
    haloOffset: 2,
  },
  [SpinnerSize.Medium]: {
    diameter: 60,
    strokeWidth: 6,
    haloWidth: 7,
    haloSize: 55,
    haloOffset: 2.5,
  },
  [SpinnerSize.Large]: {
    diameter: 120,
    strokeWidth: 6,
    haloWidth: 14,
    haloSize: 110,
    haloOffset: 5,
  },
};
