'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { setupAppInsights } from '@/global/utils/applicationInsights/setupApplicationInsights';

const ApplicationInsights = () => {
  const pathname = usePathname();

  useEffect(() => {
    if (!window.appInsights && process.env.APPLICATION_INSIGHT_CONNECTION_STRING) {
      setupAppInsights(pathname ?? '');
    }

    if (window.appInsights && pathname) {
      window.appInsights.trackPageView({ name: pathname });
    }
  }, [pathname]);

  return null;
};

export default ApplicationInsights;
