import { StoredB2BCartIdCookie, StoredB2CCartIdCookie } from '@/constants';
import {
  Account,
  ApiErrorCode,
  ApiErrorWithErrorCode,
  Cart,
  CheckoutCmsClientErrorReference,
  CreateOrderPayload,
  ErrorMessageWrappedInContent,
  GuestCustomer,
  LineItem,
  Notice,
  OrderLineItemInput,
  OrderRow,
  ProductRatePlan,
  ProductType,
} from '@/types';

import { gtmException } from '@/global/gtm';
import { createOrder } from '@/global/services/checkout';
import adyenErrorMessages from '@/global/utils/Checkout/AdyenErrorMessages';
import { getCmsErrorObjectByReference } from '@/global/utils/Cms';
import { ensure } from '@/global/utils/Ensure';
import { logoutRedirect } from '@/global/utils/logout';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

export const setCartCookie = (cart: Cart, isB2b: boolean) => {
  const cartExpiryDate = new Date();
  cartExpiryDate.setDate(new Date(cart.lastModified).getDate() + 14); // expire in two weeks

  const expires = `; expires=${cartExpiryDate.toUTCString()}`;
  const cookieName = isB2b ? StoredB2BCartIdCookie : StoredB2CCartIdCookie;
  document.cookie = `${cookieName}=${cart.id}; ${expires}; path=/`;
};

export const clearCartCookie = (isB2b: boolean) => {
  const cookieName = isB2b ? StoredB2BCartIdCookie : StoredB2CCartIdCookie;

  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

// This is currently not used since we only have 2 adyens error messages in the CMS. We might want to use this to post exact details to application insights?
export const getAdyenErrorMessage = (refusalReasonCode?: string | null): string => {
  return (
    Object.keys(adyenErrorMessages).find((key) => adyenErrorMessages[key] === refusalReasonCode) ||
    'Något gick fel med köpet'
  );
};

type CreateOrderHandlerParams = {
  account: Account | null;
  guestUser: GuestCustomer | null;
  cart: Cart | null;
  lineItemInput: OrderLineItemInput[] | null;
  identificationReference: string;
};

export const createOrderHandler = async ({
  account,
  guestUser,
  cart,
  lineItemInput,
  identificationReference,
}: CreateOrderHandlerParams) => {
  const personalId = account ? account.customer.personalId : guestUser?.personalId;
  const email = account ? account.customer.email : guestUser?.email;

  if (!personalId || !email || !cart) {
    throw new Error('Missing cart, personalId or email');
  }

  const createOrderPayload: CreateOrderPayload = {
    cartId: cart.id,
    customerContext: cart.customerContext,
    ownerId: personalId,
    email,
    userId: account?.userId,
    salesChannel: 'checkout',
    lineItemInputs: lineItemInput,
    identificationReference,
  };

  if (guestUser?.password) {
    createOrderPayload.password = guestUser.password;
  }

  return createOrder(createOrderPayload);
};

export const getVoiceProductsFromItems = (items?: Array<LineItem | OrderRow>) => {
  if (!items) return [];

  return items.filter((item) => item.offer?.productType === ProductType.Voice);
};

export const itemsContainsNewVoiceProduct = (items?: Array<LineItem | OrderRow>) => {
  if (!items) return false;
  return items.some((item) => item.offer?.productType === ProductType.Voice && !item.offer?.existingSubscription);
};

export const itemsContainsNonContractProduct = (items?: LineItem[] | OrderRow[]) => {
  if (!items) return false;
  return items.some((item) => item.offer?.ratePlan === ProductRatePlan.NonContract);
};

export const handleApiCodeError = (
  apiErrorOrErrorCode: ApiErrorCode | CheckoutCmsClientErrorReference | ApiErrorWithErrorCode,
  cmsErrors: ErrorMessageWrappedInContent<string>[],
  router?: AppRouterInstance
): Notice | null => {
  const apiErrorCode = typeof apiErrorOrErrorCode === 'string' ? apiErrorOrErrorCode : apiErrorOrErrorCode.data.message;
  const apiStatusCode = typeof apiErrorOrErrorCode === 'string' ? null : apiErrorOrErrorCode.status;
  try {
    let cmsError = getCmsErrorObjectByReference({
      cmsErrors,
      errorReferenceKey: apiErrorCode,
    });
    if (apiStatusCode === 403 && !cmsError) {
      cmsError = ensure(
        getCmsErrorObjectByReference({
          cmsErrors,
          errorReferenceKey: CheckoutCmsClientErrorReference.CLIENT_ERROR_REQUIRES_REIDENTIFICATION,
        })
      );
    }
    if (!cmsError) {
      cmsError = ensure(
        getCmsErrorObjectByReference({
          cmsErrors,
          errorReferenceKey: CheckoutCmsClientErrorReference.GENERIC,
        })
      );
    }

    const { header, body, buttonText, buttonLink } = cmsError.content;
    gtmException(body);
    return {
      header,
      description: body,
      buttonText,
      buttonOnClick: () => {
        if (apiErrorCode === ApiErrorCode.ERROR_USER_CONTEXT_MISMATCH) {
          logoutRedirect(true);
        } else if (buttonLink && router) {
          router.push(buttonLink);
        }
      },
      reference: apiErrorCode,
    };
  } catch (error) {
    return null;
  }
};
