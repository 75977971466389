import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import { HeaderLink } from '@/types';
import debounce from 'lodash.debounce';
import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

import { HeaderContainer } from '@/global/layouts/parts/Header/Header.styled';
import { isHardwareProductRoute } from '@/global/layouts/parts/Header/Header.utils';
import SecondaryLink from '@/global/layouts/parts/Header/HeaderSecondary/SecondaryLink/SecondaryLink';
import { Color } from '@/global/style/variables';

import {
  HeaderSecondaryContainer,
  HeaderSecondaryPlaceholder,
  SecondaryLinkContainer,
  ScrollLeft,
  ScrollRight,
  SecondaryLinkNav,
} from './HeaderSecondary.styled';

type HeaderSecondaryProps = {
  secondaryLinks: HeaderLink[];
  windowWidth: number;
  sticky: boolean;
};

const HeaderSecondary = (props: HeaderSecondaryProps) => {
  const { secondaryLinks, windowWidth, sticky } = props;

  const [scrollPos, setScrollPos] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const asPath = usePathname() || '';
  const linksContainerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const el = linksContainerRef.current;
    if (!el) return;

    const debouncedScroll = debounce(() => setScrollPos(el.scrollLeft + 0.5), 100);

    el.addEventListener('scroll', debouncedScroll);

    // Sort out the initial scrollposition, to put the active SecondaryLink into view
    el.querySelector('.active')?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    return () => {
      el.removeEventListener('scroll', debouncedScroll);
    };
  }, []);

  useEffect(() => {
    const el = linksContainerRef.current;
    if (!el) return;
    const scrollNeeded = window.innerWidth < el.scrollWidth;
    const elScrollPos = el.offsetWidth + el.scrollLeft;
    const enableRightArrow = Boolean(scrollNeeded && Math.round(elScrollPos) < el.scrollWidth);
    const enableLeftArrow = Boolean(scrollNeeded && el.scrollLeft > 0);

    setShowRightArrow(enableRightArrow);
    setShowLeftArrow(enableLeftArrow);
  }, [windowWidth, scrollPos]);

  const handleScroll = (direction: 'left' | 'right') => {
    const el = linksContainerRef.current;
    if (!el) return null;

    const scrollInterval = 200;
    let newScrollPos = direction === 'left' ? scrollPos - scrollInterval : scrollPos + scrollInterval;

    // Prevent scrolling past the start and end of the container
    if (newScrollPos > el.scrollWidth) {
      newScrollPos = el.scrollWidth - el.offsetWidth;
    } else if (newScrollPos < 0) {
      newScrollPos = 0;
    }

    setScrollPos(newScrollPos);
    el.scrollTo({
      left: newScrollPos,
      behavior: 'smooth',
    });

    const elScrollPos = el.offsetWidth + newScrollPos;
    setShowLeftArrow(newScrollPos !== 0);
    setShowRightArrow(elScrollPos <= el.scrollWidth);
  };

  const getIsActive = (url: string) => {
    // In my pages, the Abonnemang subheader should be marked as active for every page under /mina-sidor
    // except for routes that includes /mina-sidor/betalningar or exact match of /mina-sidor/kontoinstallningar.

    if (asPath.includes('/mina-sidor/betalningar')) {
      return asPath.includes(url);
    }

    if (asPath === '/mina-sidor/kontoinstallningar') {
      return asPath.includes(url);
    }

    if (url === '/mina-sidor/abonnemang') {
      return asPath.includes('/mina-sidor');
    }

    if (isHardwareProductRoute(asPath)) {
      return url.includes(asPath);
    }

    // Otherwise it's always exact match check
    return asPath === url.replace(/\/$/, '');
  };

  return (
    <HeaderContainer $backgroundColor={Color.White}>
      <HeaderSecondaryContainer $sticky={sticky}>
        <ScrollLeft
          className="ignore-outside-click-hamburger"
          tabIndex={-1}
          $show={showLeftArrow}
          onClick={() => handleScroll('left')}
        >
          <SvgIcon icon="chevron-left" size="small" />
        </ScrollLeft>

        <SecondaryLinkNav>
          <SecondaryLinkContainer ref={linksContainerRef}>
            {secondaryLinks.map((secondaryLink) => {
              const linkUrl = secondaryLink.link.url;
              const isActive = getIsActive(linkUrl);
              return (
                <SecondaryLink
                  key={linkUrl}
                  text={secondaryLink.text}
                  link={linkUrl}
                  active={isActive}
                  data-testid={`header-secondary-link-${secondaryLink.text}`}
                  className={isActive ? 'active' : ''}
                />
              );
            })}
          </SecondaryLinkContainer>
        </SecondaryLinkNav>

        <ScrollRight
          className="ignore-outside-click-hamburger"
          tabIndex={-1}
          $show={showRightArrow}
          onClick={() => handleScroll('right')}
        >
          <SvgIcon icon="chevron-right" size="small" />
        </ScrollRight>
      </HeaderSecondaryContainer>
      <HeaderSecondaryPlaceholder $show={sticky} />
    </HeaderContainer>
  );
};

export default HeaderSecondary;
