/**
 * @function formatPhonenumber
 *
 * @description
 *
 * @param  {type} phoneNumber {description}
 * @return {type} {description}
 */
export const formatPhonenumber = (phoneNumber: string) => {
  // 0793327290
  // 07100003292613

  let num = phoneNumber;
  if (typeof phoneNumber !== 'string') {
    num = `${phoneNumber}`;
  }
  num = num.replace('-', '');
  if (num.length === 10) {
    return `${num.substr(0, 4)}-${num.substr(4, 2)} ${num.substr(6, 2)} ${num.substr(8, 2)}`;
  }
  if (num.length === 9) {
    return `${num.substr(0, 4)} ${num.substr(4, 3)} ${num.substr(7, 2)}`;
  }
  if (num.length === 14) {
    // Broadband
    return `${num.substr(0, 4)}-${num.substr(4, 3)} ${num.substr(7, 3)} ${num.substr(10, 4)}`;
  }
  return `${num.substr(0, 2)} ${num.substr(2)}`;
};

export const formatDate = (dateString: string) => {
  const d = new Date(dateString);

  d.setUTCHours(d.getUTCHours() + 2);

  return d.toLocaleDateString('sv-SE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const formatDateTime = (dateString: string, formatOverrides: Intl.DateTimeFormatOptions = {}) => {
  return new Intl.DateTimeFormat('sv-SE', {
    timeZone: 'Europe/Stockholm',
    timeStyle: 'short',
    dateStyle: 'short',
    ...formatOverrides,
  }).format(new Date(dateString));
};

export const formatDateToMonthString = (dateString: string) => {
  const d = new Date(dateString);
  return [
    'januari',
    'februari',
    'mars',
    'april',
    'maj',
    'juni',
    'juli',
    'augusti',
    'september',
    'oktober',
    'november',
    'december',
  ][d.getMonth()];
};

export const addDaysToDate = (date: string, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const daysUntilFirstOfNextMonth = () => {
  const date = new Date();
  const time = new Date(date.getTime());
  time.setMonth(date.getMonth() + 1);
  time.setDate(0);
  return time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
};

export const firstOfNextMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() + 1, 1);
};

export const isSameMonth = (d1: Date, d2: Date) => {
  return d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
};

export const isPastDate = (date: string) => {
  return new Date(date) < new Date();
};

export const formatPrice = (number: number, currency: string) => {
  const parsedNumber = `${number.toFixed(2)}`.replace('.', ',');
  let currencyString = '';
  if (currency.substr(0, 1) !== ' ') {
    currencyString = ` ${currency}`;
  }
  return `${parsedNumber}${currencyString}`;
};

export const formatPriceNoDecimals = (number: number, currency: string) => {
  const parsedNumber = number.toString().replace('.', ',');
  return `${parsedNumber} ${currency}`;
};

export const formatAmountWithSpaces = (amount?: string | number) => {
  if (amount === undefined || amount === null) return '';
  const amountAsString = amount.toString();
  const parts = amountAsString.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const formatDisplayPrice = (
  amountInclVat?: number | string,
  showExclVAT?: boolean,
  amountExclVat?: number | string
) => {
  const parsedAmountInclVat =
    typeof amountInclVat === 'string' ? parseFloat(amountInclVat.replace(',', '.')) : amountInclVat;
  const parsedAmountExclVat =
    typeof amountExclVat === 'string' ? parseFloat(amountExclVat.replace(',', '.')) : amountExclVat;

  if (!parsedAmountInclVat) return '';
  let number: string | number = parsedAmountInclVat; // convert to number using unary plus https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Unary_plus
  if (showExclVAT) {
    number = parsedAmountExclVat || number * 0.8;
  }

  if (!Number.isInteger(number)) {
    number = `${number.toFixed(2)}`.replace('.', ',');
  }

  return `${number}`;
};

export const roundAmountToMaxTwoDecimals = (price: string) => {
  const asNumber = parseFloat(price);
  const rounded = Math.round(asNumber * 100) / 100;

  return rounded;
};

export const formatCustomerId = (idNumber: string, isB2b: boolean) => {
  // For b2b it is org number.
  if (isB2b) {
    return `${idNumber.substr(0, idNumber.length - 4)}-${idNumber.substr(idNumber.length - 4)}`;
  }
  // Check if the PNO is swedish
  if (/^(19|20)?(\d{6}([-+]|\s)\d{4}|(?!19|20)\d{10})$/.test(idNumber)) {
    return `${idNumber.substr(0, idNumber.length - 4)}-${idNumber.substr(idNumber.length - 4)}`;
  }
  return idNumber;
};
