import Typography from '@/newComponents/Typography/Typography';

import { DetailedHTMLProps, ElementType, InputHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import { Color } from '@/global/style/variables';
import { FontWeight } from '@/constants';

import RadioCard from '@/components/RadioCard/RadioCard';
import styles from './RadioLinkButton.module.css';

type RadioLinkButtonProps = {
  className?: string;
  label: ReactNode;
  price?: ReactNode;
  finePrint?: ReactNode;
  ['aria-label']?: string;
  elementAs?: ElementType;
  selected?: boolean;
  ['data-testid']: string;
  onChange: () => void;
  children?: ReactNode;
};

type ExtendedRadioLinkButtonProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  RadioLinkButtonProps;

const RadioLinkButton = ({
  className,
  label,
  price,
  finePrint,
  checked,
  selected = false,
  id,
  name,
  onChange,
  disabled,
  children = null,
  'data-testid': dataTestId,
  'aria-label': ariaLabel = 'radio-button',
  elementAs,
}: ExtendedRadioLinkButtonProps) => {
  const Element = elementAs ?? 'div';

  return (
    <Element
      className={classNames(styles.radioLinkButtonContainer, className, {
        [styles.selected]: selected,
      })}
      onClick={onChange}
    >
      <RadioCard
        id={id}
        data-testid={dataTestId ?? ''}
        checked={checked}
        disabled={disabled}
        aria-label={ariaLabel}
        name={name}
        onChange={onChange}
        focusVisible={false}
      >
        <div className={styles.radioCardBody}>
          {/* <RadioCardLabel fontWeight={FontWeight.SemiBold}>{label}</RadioCardLabel> */}
          <Typography text={label} fontWeight={FontWeight.SemiBold} />

          {price && (
            <div className={styles.radioCardInfoContainer}>
              <Typography text={price} color={Color.Hallon1} fontWeight={FontWeight.SemiBold} />
              {finePrint && <Typography text={finePrint} align="right" />}
            </div>
          )}

          {children && <div className={styles.radioChildContainer}>{children}</div>}
        </div>
      </RadioCard>
    </Element>
  );
};

export default RadioLinkButton;
